<template>
  <div style="height: 100vh;display: flex;flex-direction: column;overflow: hidden;">
    <div style="display: flex;align-items: center;padding: 1rem 2rem 1rem 2rem;
          justify-content: space-between;position: absolute;top: 0;left: 0;right: 0;z-index: 10000">
    </div>
    <div style="flex: 1">
      <router-view/>
      <!--图片底部 -->
<!--      <img style="z-index: 0;height: 100%;width: 100%; background-color: #808080"-->
<!--            src="https://source.cengceng.chat/basic/bg3.jpg" />-->
      <!--  滚动导航   -->
      <div id="menu" class="right" v-show="currentPath==homePath">
        <ul>
          <li :data-menuanchor="item" v-for="item in menuList" :key="item"></li>
        </ul>
      </div>
      <!-- 底部导航-->
      <bottom-bar  v-if="currentPath!==homePath||
       currentHash=='#'+menuList[menuList.length-1]"/>
    </div>
  </div>
</template>

<script>
import {config} from "@/common/Config";
import BottomBar from "@/components/BottomBar.vue";

let topBarList = [{title: "首页", path: "/mobile/home"},
  // {title: "关于只只", path: "/mobile/about"},
  {title: "关于我们", path: "/mobile/contact"
}]

export default {
  name: 'MobilePage',
  data() {
    return {
      selectIndex: "home",
      config: config,
      homePath: topBarList[0].path,
      currentPath: this.$route.path,
      currentHash:this.$route.hash,
      menuList: config.getHomeKeyList(),
    };
  },
  watch: {
    $route: {
      handler: function(val){
        console.log("------",this.$route.path+"---"+this.$route.hash)
        this.currentPath=val.path
        this.currentHash=val.hash
      },
      deep: true
    }
  },
  components: {
    BottomBar
  }
}
</script>

<style scoped>
#menu {
  position: absolute;
  top: 50%;
  margin-top: -32px;
  z-index: 10000
}

#menu.right {
  right: 10px;
}

#menu ul li {
  list-style: none;
  width: 6px;
  height: 6px;
  animation: unselect 500ms;
  background: #ddd;
  margin: 12px;
  border-radius: 10px;
}

#menu li.active {
  background: #113de8;
  height: 18px;
  animation: select 500ms;
}

@keyframes select {
  from {
    height: 6px;
  }
  to {
    height: 18px;
  }
}

@-webkit-keyframes select {
  from {
    height: 6px;
  }
  to {
    height: 18px;
  }
}

@keyframes unselect {
  from {
    height: 18px;
  }
  to {
    height: 6px;
  }
}

@-webkit-keyframes unselect {
  from {
    height: 18px;
  }
  to {
    height: 6px;
  }
}
</style>
