
export const config={
    //请求HOST
    host:"/api/",
    //下载地址
    iosPath:"https://apps.apple.com/cn/app/id414478124",
    androidPath:"https://apps.apple.com/cn/app/id333206289",
    //首页背景视频
    backgroundVide:"https://source.cengceng.chat/basic/backgroundVideo.mp4",
    //公司介绍地址图
    addressPath:"https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/common/company.jpg",
    //首页产品介绍图
    homeCommonList: [
        {
            title: "自定义多账本",
            subTitle: "不同场景都能专业应对",
            desc: "支持多种类型的账本，预置适配各场景下的记账模块，支持完全自定义编辑，既高效又灵活",
            image:"https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/book-icon.png",
            url: "book"
        },
        {
            title: "强大的资产管理",
            subTitle: "多类型资产与账单联动",
            desc: "支持各种资产类型配置，记账时与你的资产联动，轻松查看各项变动情况和你的资产全貌，超省心",
            image:"https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/capital-icon.png",
            url: "capital"
        },
        {
            title: "懂你的数据分析",
            subTitle: "AI让记账越用越好用",
            desc: "只只记账通过AI智能为你优化数据分析，让你更了解你的财务提升点",
            image:"https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/analyze-icon.png",
            url: "analyze"
        },
        {
            title: "和好友一起记账",
            subTitle: "分配权限快速加入账本",
            desc: "支持多人共享记账，提供权限管理功能，既能分享，也很安全",
            image:"https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/share-icon.png",
            url: "share"
        },
    ],
     getHomeKeyList(){
        let menuList=["main"];
        this.homeCommonList.map((item)=>{
            menuList.push(item.url);
        })
        return menuList;
    }
}

