<template>
  <div style="height: 100%;padding-top: 130px;display: flex;flex-direction: column;align-items: center;overflow: auto">
    <div style="display: flex;align-items:center">
      <img :src=config.addressPath style="height:320px ;border-radius: 60px;border: 10px solid #eee;">
      <div style="margin-left: 100px;font-size: 18px">
        <div style="color: #ddd;width: 360px"><span style="font-weight: 500;color: white;line-height: 52px">地址：</span>杭州市余杭区仓前街道EFC欧美金融城T3幢16F</div>
        <div style="color: #ddd;width: 360px"><span style="font-weight: 500;color: white;line-height: 52px">邮编：</span>311121</div>
        <div style="color: #ddd;width: 360px"><span style="font-weight: 500;color: white;line-height: 52px">官方邮箱：</span>zhizhibook@foxmail.com</div>
        <div style="color: #ddd;width: 360px"><span style="font-weight: 500;color: white;line-height: 52px">微信公众号：</span>只只记账</div>
<!--        <div style="color: #ddd;width: 360px"><span style="font-weight: 500;color: white;line-height: 52px">抖音：</span>只只App</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {config} from "@/common/Config";

export default {
  computed: {
    config() {
      return config
    }
  }
}
</script>

<style scoped>

</style>
