<template>
  <div style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 110;
  background-image: url('https://source.cengceng.chat/basic/bg2.jpg');object-fit: cover;background-size: 100% 100%;">
    <div id="fullpage">
      <div class="section" style="background: rgba(0,0,0,0.2)">
        <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;margin-top: 5rem;height: 100%">
          <div style="display: flex;align-items: center;margin-bottom: 1rem">
            <!--              background-color: #0057F8-->
            <div style="width: 3.8rem;height: 3.8rem;display: flex;align-items: center;justify-content: center;
border: 3px solid #fff;border-radius: 0.6rem;">
              <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/logo.png" style="width:1.2rem"/>
            </div>
            <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/logo-name.png" style="height:3rem;margin-left: 1rem"/>
          </div>
          <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/title.png" style="width:70%"/>
<!--          <div style="color: white;margin-top: 12px;font-weight: 400;font-size: 1rem">越用越好用的AI记账软件</div>-->
          <div style="flex: 1;display: flex;align-items: center;justify-content: center;width: 100%">
            <div style="display: flex;flex-direction: column;align-items: center;height: 100%;width: 100%">
              <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/qrcode.png" style="width: 8rem;margin-top: 3rem"/>
              <div style="flex: 1;overflow: hidden;position: relative;width: 100%;">
                <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/top-icon.png" style="width: 120%;overflow: hidden;
                position: absolute;bottom: -1rem;
            left: 0;right: 0;margin-left: -10%"/>
              </div>
<!--              <div style="border: 0.5px solid rgba(255,255,255,0.4);border-radius: 8px;display: flex;align-items: center;-->
<!--              padding: 0.4rem 1.3rem 0.4rem 1rem;color: white;font-size: 11px;background:  rgba(0,0,0,0.1);cursor: pointer"-->
<!--                   @click="()=>{openUrl(config.iosPath)}">-->
<!--                <img src="../../asset/apple.png" style="height: 2.2rem;">-->
<!--                <div style="margin-left: 0.6rem;">-->
<!--                  <div style="font-size: 1.1rem;font-weight: 600;margin-bottom:0.2rem;">苹果下载</div>-->
<!--                  <div style="font-size: 0.6rem;">APP STORE</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="border: 0.5px solid rgba(255,255,255,0.4);border-radius: 8px;display: flex;align-items: center;-->
<!--              padding: 0.4rem 1.3rem 0.4rem 1rem;color: white;font-size: 11px;background: rgba(0,0,0,0.1);cursor: pointer;margin-top:1.8rem"-->
<!--                   @click="()=>{openUrl(config.androidPath)}">-->
<!--                <img src="../../asset/android.png" style="height: 2.2rem;">-->
<!--                <div style="margin-left: 0.6rem">-->
<!--                  <div style="font-size: 1.1rem;font-weight: 600;margin-bottom:0.2rem;">安卓下载</div>-->
<!--                  <div style="font-size: 0.6rem;">ANDROID</div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="section" style="background: rgba(0,0,0,0.2)" v-for="(item,index) in config.homeCommonList" :key="index">
        <home-item :info="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import HomeItem from "@/page/mobile/MobileHomeItem.vue";
import fullpage from 'fullpage.js';
import {config} from "@/common/Config";
// import HomeEnd from "@/page/mobile/MobileHomeEnd.vue";
export default {
  name: 'WebHome',
  props: [],
  mounted() {

      this.fullPage=new fullpage('#fullpage', {
        // navigation: true,
        menu: "#menu",
        anchors: config.getHomeKeyList(),
        // afterLoad: (origin, destination) => {
        //   this.homeIndex = destination.index;
        // }
      });
  },
  beforeDestroy() {
    this.fullPage.destroy('all');
  },
  data() {
    return {
      config:config,
      fullPage:null,
    };
  },
  components: {
    // HomeEnd,
    HomeItem
  },
  methods: {
    openUrl(url){
      window.open(url, '_blank');
    }
  },
  computed: {},
}
</script>

<style scoped>
.section {
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
}


</style>
