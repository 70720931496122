<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted () {

  },
  methods: {

  }

}
</script>

<style>
#app {
  height: 100%;
}
</style>
