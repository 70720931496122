import {config} from "@/common/Config";


export function post(options, callback, errCallback) {
  let timeLimit = options.timeout || 60000;
  const params = options.params || {};
  const paramsString = JSON.stringify(params)
  const url = options.url;
  const noLoading = options.noLoading;
  const noErrorTips = options.noErrorTips;

  const token = getKey('token');
  const _fetchParam = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: paramsString
  };
  if (token) {
    _fetchParam.headers.TOKEN = token
  }
  const loadingText = options.loadingText;
  const successText = options.successText;
  if (loadingText && !noLoading) {
    ToastLoading(loadingText)
  }
  Promise.race([fetch(config.host+url, _fetchParam),
    new Promise(function (resolve) {
      setTimeout(() => resolve(new Error('request timeout')), timeLimit)
    })])
    .then(
      (res) => {
        if (!noLoading) {
          ToastClear();
        }
        if (res.status == 200) {
          return res.json();
        }
      })
    .then((res) => {
      if (!callback) {
        return
      }
      if (res) {
        if (res.code == 0) {
          if (successText && !noLoading) {
            ToastSuccess(successText);
          }
          callback(res.content, res.msg);
        } else {
          if (!noErrorTips) {
            ToastCustomer(res.msg || "网络异常，请稍后再试")
          }
          errCallback && errCallback(res.code, res.msg);
        }
      } else {
        if (!noErrorTips) {
          ToastCustomer("服务器异常");
        }
        errCallback && errCallback(50000, "服务器异常");
      }
    })
    .catch((err) => {
        if (!noErrorTips) {
          ToastCustomer("网络异常");
        }
        errCallback && errCallback(50000, "网络异常");
        throw err;
      }
    );
}


export function IsURL(str_url) {
  var strRegex = '^((https|http|ftp|rtsp|mms)?://)'
    + '?(([0-9a-z_!~*\'().&=+$%-]+: )?[0-9a-z_!~*\'().&=+$%-]+@)?' //ftp的user@ 
    + '(([0-9]{1,3}.){3}[0-9]{1,3}' // IP形式的URL- 199.194.52.184 
    + '|' // 允许IP和DOMAIN（域名） 
    + '([0-9a-z_!~*\'()-]+.)*' // 域名- www. 
    + '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' // 二级域名 
    + '[a-z]{2,6})' // first level domain- .com or .museum 
    + '(:[0-9]{1,4})?' // 端口- :80 
    + '((/?)|' // a slash isn't required if there is no file name 
    + '(/[0-9a-z_!~*\'().;?:@&=+$,%#-]+)+/?)$';
  var re = new RegExp(strRegex);
  //re.test() 
  if (re.test(str_url)) {
    return (true);
  } else {
    return (false);
  }
}


export function isString(str) {
  return ((str instanceof String) || (typeof str).toLowerCase() == 'string');
}

export function isEmpty(str) {
  if (str && str != "") {
    return false;
  }
  return true;
}

// eslint-disable-next-line no-unused-vars
export function ToastFail(word) {

}

// eslint-disable-next-line no-unused-vars
export function ToastSuccess(word) {

}

// eslint-disable-next-line no-unused-vars
export function ToastLoading(word, autoMiss) {

}

export function ToastClear() {

}

// eslint-disable-next-line no-unused-vars
export function ToastCustomer(message, icon) {

}

export function saveKey(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getKey(key) {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  } else {
    return null;
  }
}

export function removeKey(key) {
  localStorage.removeItem(key);
}

export function clearAllKey() {
  localStorage.clear();
}

export function startPage(context, pageKey, params) {
  context && context.$router.push({name: pageKey, params: params});
}

export function replacePage(context, pageKey, params) {
  context && context.$router.replace({name: pageKey, params: params});
}




//时间戳转换方法    date:时间戳数字
export function formatTime(time, format) {
  var date = new Date(time);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
  var mm = ":" + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
  var ss = ":" + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  if (format == "DAY") {
    return YY + MM + DD;
  }
  if (format == "MINUTES") {
    return MM + DD + " " + hh + mm;
  }
  return YY + MM + DD + " " + hh + mm + ss;
}


