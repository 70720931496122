import Vue from 'vue'
import Router from 'vue-router'
import Document from "@/page/common/Document.vue";
import Mobile from "@/page/Mobile.vue";
import MobileHome from "@/page/mobile/MobileHome.vue";
// import AboutUs from "@/page/common/AboutUs.vue";
import WebContactUs from "@/page/web/WebContactUs.vue";
import Web from "@/page/Web.vue";
import WebHome from "@/page/web/WebHome.vue";
import MobileContactUs from "@/page/mobile/MobileContactUs.vue";
Vue.use(Router);

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router =  new Router({
  mode: 'history',
  routes: [
    //隐私协议、用户须知等公共页面
    {
      path: '/doc',
      component: Document,
      // props: (route) => ({ docType: route.query.docType })
    },
      //移动端官网页面
    {
      path: '/mobile',
      component:  Mobile,
      children:[
        {
          path: 'home',
          component: MobileHome,
        },
        // {
        //   path: 'about',
        //   component: AboutUs,
        // },
        {
          path: 'contact',
          component: MobileContactUs,
        },
        { path: '*', redirect: 'home' },
        { path: '', redirect: 'home' }, //vue的bug，在children中*号无法匹配空字符串
      ]
    },
    //PC端官网页面
    {
      path: '/',
      component: Web,
      children:[
        {
          path: 'home',
          component: WebHome,
        },
        // {
        //   path: 'about',
        //   component: AboutUs,
        // },
        {
          path: 'contact',
          component: WebContactUs,
        },
        { path: '*', redirect: 'home' },
      ]
    }
  ]
});



router.beforeEach((to, from, next) => {
  // console.log("-----to-----",to.path);

  let isMobile=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  let toPath=to.path;
  if(toPath.startsWith("/doc")){
    next();
    return;
  }
  if(isMobile){
    if(toPath.startsWith("/mobile")){
       next();
    }else {
      next({path: "/mobile"+toPath});
    }
  }else {
    if(toPath.startsWith("/mobile")){
      next({path: toPath.replace("/mobile","")});
    }else {
      next();
    }
  }

});



export default router;