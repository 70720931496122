<template>
  <div style="display: flex;align-items: center;color: white;flex-direction: column;
  height: 100%;">
    <div style="font-size: 2rem;margin-top: 5rem">{{ info.title }}</div>
    <div style="font-size: 1rem;margin-top: 0.7rem;margin-bottom: 4rem;">{{ info.subTitle }}</div>
    <div style="flex: 1;width: 100%;background-size:auto 72% ;object-fit: cover;
    background-repeat: no-repeat;background-position:top center;"
         :style="{'background-image':'url('+info.image+')'}"
    />
    <div style="height: 2rem;width: 100%;"/>
  </div>
</template>

<script>


export default {
  name: 'WebHomeItem',
  props: ["info"],
  data() {

    return {};
  },
  components: {},
  methods: {},
  computed: {},
}
</script>

<style scoped>

</style>
