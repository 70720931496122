<template>
  <div style="height: 100%;">
    <!--顶部Bar-->
    <div style="display: flex;align-items: center;padding: 20px 28px 20px 48px;
          justify-content: space-between;position: absolute;top: 0;left: 0;right: 0;z-index: 1000">
      <div/>
      <div style="display: flex;align-items: center;">
        <router-link :to="item.path"  style="color: rgba(255, 255, 255, 0.6)" v-for="item in topBarList" :key="item.path">
          <div  style="margin-right:48px;display: flex;flex-direction: column;align-items: center;font-size: 18px;cursor: pointer">
            <div :style="{'color': item.path==currentPath?'#fff':null}" style="font-weight: 500">{{item.title}}</div>
            <div style="margin-top: 12px;height: 12px">
              <div v-show="item.path==currentPath" style="width: 24px;height: 6px;border-radius: 3px;background-color: white"/>
            </div>
          </div>
        </router-link>
<!--        <div style="width: 55px;height: 55px">-->
<!--          <div v-show="currentPath==homePath" style="cursor: pointer;" @click="isMute=!isMute">-->
<!--            <img v-if="isMute" src="../asset/mute.png" style="height: 30px;"/>-->
<!--            <img v-else src="../asset/volume.png" style="height: 30px;"/>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <router-view></router-view>
    <!--视频的底部 -->
<!--    <video style="object-fit: cover;z-index: 0;height: 100%;width: 100%;-->
<!--               background-color: #808080" :muted=isMute autoplay loop class="video-bg">-->
<!--      <source :src="config.backgroundVide"-->
<!--              type="video/mp4">-->
<!--    </video>-->
    <!-- 底部导航-->
    <bottom-bar  v-if="currentPath!==homePath||
    currentHash=='#'+menuList[menuList.length-1]"/>
  </div>
</template>

<script>

import {config} from "@/common/Config";
import BottomBar from "@/components/BottomBar.vue";

let topBarList=[{title:"首页",path:"/home"},
  // {title:"关于只只",path:"/about"},
  {title:"关于我们",path:"/contact"}]
export default {
  name: 'WebPage',
  watch: {
    $route: {
      handler: function(val){
        console.log("------",this.$route.path+"---"+this.$route.hash)
        this.currentPath=val.path
        this.currentHash=val.hash
      },
      deep: true
    }
  },
  components:{BottomBar},
  data() {
    return {
      homePath:topBarList[0].path,
      topBarList: topBarList,
      config: config,
      isMute: true,
      currentPath:this.$route.path,
      currentHash:this.$route.hash,
      menuList:config.getHomeKeyList(),
    };
  }
}
</script>

<style scoped>


</style>
