<template>
  <div style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 110">
    <div id="fullpage">
      <div class="section" style="background: transparent">
        <div style="display: flex;align-items: flex-start;justify-content: center;margin-top: 100px">
          <div style="flex: 1;display: flex;justify-content:flex-end">
            <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/top-icon.png" style="width:100%;"/>
          </div>
          <div style="flex: 0.8;display: flex;flex-direction: column;align-items: center;padding-right: 60px;margin-top: 120px">
            <div style="display: flex;align-items: center;">
<!--              background-color: #0057F8-->
              <div style="width: 72px;height: 72px;display: flex;align-items: center;justify-content: center;
border: 4px solid #fff;border-radius: 16px;">
                <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/logo.png" style="width:26px"/>
              </div>
              <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/logo-name.png" style="height:60px;margin-left: 20px"/>
            </div>
            <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/title.png" style="width: 320px;margin-top: 12px"/>
              <img src="https://tz-book.oss-cn-hangzhou.aliyuncs.com/asset/web/qrcode.png" style="width: 120px;margin-top: 60px"/>
          </div>
        </div>
      </div>
      <div class="section" style="background: rgba(200,200,200,0.1)" v-for="(item,index) in config.homeCommonList" :key="index">
        <home-item :info="item" />
      </div>
    </div>
    <!-- 滚动导航-->
    <div id="menu" class="right">
      <ul>
        <li :data-menuanchor="item" v-for="item in menuList"
            :key="item"
            @click="fullPage.moveTo(item)"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import HomeItem from "@/page/web/WebHomeItem.vue";
import fullpage from 'fullpage.js';
import {config} from "@/common/Config";
export default {
  name: 'WebHome',
  props: [],
  mounted() {

      this.fullPage=new fullpage('#fullpage', {
        // navigation: true,
        menu: "#menu",
        anchors: config.getHomeKeyList(),
        // afterLoad: (origin, destination) => {
        //   // this.homeIndex = destination.index;
        // }
      });
  },
  beforeDestroy() {
    this.fullPage.destroy('all');
  },
  data() {
    return {
      config:config,
      fullPage:null,
      menuList:config.getHomeKeyList(),
    };
  },
  components: {
    HomeItem
  },
  methods: {
    openUrl(url){
      window.open(url, '_blank');
    }
  },
  computed: {},
}
</script>

<style scoped>
.section {
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
}

#menu{ position: absolute; top: 50%; margin-top: -32px;z-index: 10000}
#menu.right{  right: 10px;}
#menu ul li{
  list-style: none;
  width: 8px; height: 8px;
  animation: unselect 500ms;
  background: #ddd; margin:12px;
  border-radius: 10px;
  cursor: pointer;
}
#menu li.active{
  background: #113de8;height: 24px;
  animation: select 500ms;
}
@keyframes select {
  from {
    height:8px;
  }
  to {
    height: 24px;
  }
}

@-webkit-keyframes select {
  from {
    height:8px;
  }
  to {
    height: 24px;
  }
}
@keyframes unselect {
  from {
    height: 24px;
  }
  to {
    height:8px;
  }
}

@-webkit-keyframes unselect {
  from {
    height: 24px;
  }
  to {
    height:8px;
  }
}
</style>
