<template>
  <div style="overflow-y: auto;position: absolute;left: 0rem;right: 0rem;top: 0rem;bottom: 1rem">
    <div v-html="docText" style="border-radius: 1rem;padding:0 1rem 0.2rem 1rem"></div>
  </div>
</template>

<script>

import {post} from "@/common/Common";

export default {
  name:"DocPage",
  mounted () {
     this.loadDocument();
  },
  data () {
    // let docType=this.$route.query.docType;
    return {
      docType:this.$route.query.docType,
      docText:''
    };
  },
  components: { },
  methods: {
    loadDocument(){
       let docType= this.$route.query.docType;
       if(docType==null){
         alert("没有传入文件类型")
         return;
       }
      post({
        url: "basic/doc/agreement",
        params: {
          docType:docType
        },
        noLoading: true,
      }, (content) => {
         console.log("",content.content)
        this.docText=content.content;
      }, (code,msg) => {
          this.docText=msg;
      });


    }
  }

}
</script>

<style scoped>

</style>
