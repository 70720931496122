<template>
  <div style="margin-left: 10%;margin-right: 10%;height: 100%;display: flex;align-items: flex-start;
       margin-top: 100px;justify-content: center;color: white">
      <div style="flex: 1;margin-right: 80px;overflow: hidden;display: flex;justify-content: flex-end">
        <img  :src="info.image" style="width: 100%;">
      </div>
      <div style="flex: 1;display: flex;flex-direction: column;align-items: flex-start;padding-top: 50px;">
        <div style="font-size: 75px;font-weight: 600;line-height: 106px;">{{info.title}}</div>
        <div style="margin-top: 40px;font-size: 29px;font-weight: 500;">{{info.subTitle}}</div>
        <div style="margin-top: 20px;font-size: 18px;line-height: 32px;color: rgba(255,255,255,0.4);font-weight: 400;
        width: 380px">{{info.desc}}</div>
      </div>
  </div>
</template>

<script>


export default {
  name: 'WebHomeItem',
  props: ["info"],
  data() {

    return {

    };
  },
  components: {

  },
  methods: {

  },
  computed: {

  },
}
</script>

<style scoped>

</style>
