<template>
  <div style="height: 100%;padding-top: 6rem;display: flex;
  flex-direction: column;align-items: center;overflow: hidden">
    <div style="display: flex;flex-direction: column;flex:1;padding:0 1.5rem">
      <img :src=config.addressPath style="width: 80% ;border-radius: 2rem;border: 8px solid #eee;">
      <div style="font-size: 1.1rem;margin-top: 1.5rem;color: #ddd;">
        <div><span style="font-weight: 500;color: white;line-height: 2.2rem">地址：</span>杭州市余杭区仓前街道EFC欧美金融城T3幢16F</div>
        <div><span style="font-weight: 500;color: white;line-height: 2.2rem">邮编：</span>311121</div>
        <div><span style="font-weight: 500;color: white;line-height: 2.2rem">官方邮箱：</span>zhizhibook@foxmail.com</div>
        <div><span style="font-weight: 500;color: white;line-height: 2.2rem">微信公众号：</span>只只记账</div>
<!--        <div><span style="font-weight: 500;color: white;line-height: 2.2rem">抖音：</span>只只App</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {config} from "@/common/Config";

export default {
  computed: {
    config() {
      return config
    }
  }
}
</script>

<style scoped>

</style>
